import { getMineInfo } from '@/api/user'
const state = () => ({
  userInfo: JSON.parse(localStorage.getItem('hg-org-userId')) || null,
  mineInfo: JSON.parse(localStorage.getItem('MINE_INFO')) || null
  // {
  //   avatarId: '',
  //   idCard: null,
  //   userFrom: null,
  //   identity: null,
  //   status: null,
  //   nickname: '',
  //   realName: null,
  //   code: null,
  //   parentId: '',
  //   userType: null,
  //   inviteCode: null,
  //   goldsValue: 0,
  //   balance: 0,
  //   coinNum: 0,
  //   mobile: '',
  //   delFlag: 0,
  //   parentInviteCode: '',
  //   uid: ''
  // }
})

// getters
const getters = {}

// actions
const actions = {
  // getMineInfo({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     getMineInfo()
  //       .then((res) => {
  //         if (res.success) {
  //           localStorage.setItem('MINE_INFO', JSON.stringify(res.data))
  //           commit('SET_MINE_INFO', res.data)
  //         }
  //         resolve(res)
  //       })
  //       .catch((error) => {
  //         reject(error)
  //       })
  //   })
  // }
}

// mutations
const mutations = {
  SET_USER_INFO(state, params) {
    state.userInfo = params
  },
  SET_MINE_INFO(state, params) {
    state.mineInfo = params
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
