<template>
  <van-radio-group v-model="payMode" @change="changePay">
    <div class="gold pay-item" v-if="payTypes.length>=1 && payTypes.includes(5)" @click="changePay(5)">
      <div><img src="../../assets/images/pay/goldIngot.png" class="goldIngotItem">元宝支付</div>
      <div>
        <i class="balance-i">余额：{{ (+coinNum).toFixed(6) }}</i>
        <van-radio :name="5" checked-color="#BA2927">
          <template #icon="props">
            <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
          </template>
        </van-radio>
      </div>
    </div>
    <div v-if="payTypes.length>=1">
      <div class="gold pay-item" v-if="goldPay" @click="changePay(4)">
        <div><i class="icon"></i>金豆支付(7折)</div>
        <div>
          <i class="balance-i">剩余金豆：¥{{ (+userInfo?.goldsValue).toFixed(2) }}</i>
          <van-radio :name="4" checked-color="#BA2927">
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
            </template>
          </van-radio>
        </div>
      </div>
      <div class="balance pay-item" v-if="payTypes.includes(3)" @click="changePay(3)">
        <div><i class="icon"></i>余额支付</div>
        <div>
          <i class="balance-i">余额：¥{{ (+userInfo?.balance).toFixed(2) }}</i>
          <van-radio :name="3" checked-color="#BA2927">
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
            </template>
          </van-radio>
        </div>
      </div>
      <div class="wx pay-item" v-if="source !== 'zfb' && payTypes.includes(1)" @click="changePay(1)">
        <div><i class="icon"></i>微信支付</div>
        <div>
          <van-radio :name="1" checked-color="#BA2927">
            <template #icon="props"> <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" /> </template
          ></van-radio>
        </div>
      </div>
      <!-- <div class="zfb pay-item" v-if="source !== 'zfb'" @click="changePay(2)"> -->
      <div class="zfb pay-item" v-if="payTypes.includes(2)" @click="changePay(2)">
        <div><i class="icon"></i>支付宝支付</div>
        <div>
          <van-radio :name="2" checked-color="#BA2927">
            <template #icon="props"> <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" /> </template
          ></van-radio>
        </div>
      </div>
    </div>
  </van-radio-group>
</template>
<script>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getSource } from '@/utils/browser'
import { defineComponent, reactive, toRefs, ref, computed, watch, onMounted } from 'vue'
export default defineComponent({
  name: 'PayType',
  components: {},
  props: {
    goldPay: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: 3
    },
    payTypes:{
      type:Array,
      default:[]
    },
    coinNum:{
      type:Number,
      default:0
    }
  },
  model: {},
  setup(props, { emit }) {
    const activeIcon = require('@/assets/images/pay/activeIcon.png')
    const inactiveIcon = require('@/assets/images/pay/inactiveIcon.png')
    const payMode = ref(3)
    const router = useRouter()
    const store = useStore()
    const userType = computed(() => store.state.accountManagement.userInfo?.userType)
    const state = reactive({
      payType: {
        1: '微信',
        2: '支付宝',
        3: '余额',
        4: '金豆'
      }
    })
    // store.dispatch('user/getMineInfo')
    const userInfo = computed(() => {
      return store.state.user.mineInfo
    })
    // 双向绑定两个值
    watch(payMode, (newCode, prevCode) => {
      console.log(6767,newCode);
      
      emit('update:value', newCode)
    })
    
    watch(
      props,
      (newCode, prevCode) => {
        let { value } = props
        payMode.value = value
      },
      { immediate: true, deep: true }
    )
    const changePay = (type) => {
      if (userType.value === 4 && type === 3) {
        return
      }
      payMode.value = type
    }
    const source = getSource()
    onMounted(async () => {
      console.log(676767,props.payTypes);
      // await store.dispatch('accountManagement/getUserInfo')
    })
    return {
      inactiveIcon,
      activeIcon,
      payMode,
      changePay,
      source,
      userInfo,
      userType,
      ...toRefs(state)
    }
  }
})
</script>
<style scoped lang="scss">
@function rem($px) {
   @return calc(#{$px} / 35.7 * 1rem);
}
.pay-mode {
  width: rem(351);
  background: #ffffff;
  border-radius: rem(4);
  padding: rem(8) rem(12);
  box-sizing: border-box;
  .balance,
  .wx,
  .zfb,
  .gold {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(8);
    padding: rem(12) 0;
    box-sizing: border-box;
    > div {
      display: flex;
      align-items: center;
      font-size: rem(16);
      font-weight: 500;
      color: #343434;
      line-height: rem(22);
      .balance-i {
        font-style: normal;
        font-size: rem(14);
        font-weight: 400;
        color: #9d9d9d;
        line-height: rem(20);
        margin-right: rem(10);
      }
    }
    .icon {
      width: rem(24);
      height: rem(24);
      margin-right: rem(8);
    }
  }
  .img-icon {
    width: 20px;
    height: 20px;
  }
  .zfb {
    // margin: 0;
    // display: none;
  }
  .gold {
    .icon {
      background: url('../../assets/images/shopCar/gold_icon.svg') no-repeat 0 0/100%;
    }
    .goldIngotItem{
      width: 24px;
      height: 24px;
      margin: 0 8px 0 0;
    }
  }
  .balance {
    .icon {
      background: url('../../assets/images/shopCar/balance.svg') no-repeat 0 0/100%;
    }
  }
  .wx {
    .icon {
      background: url('../../assets/images/shopCar/wx.svg') no-repeat 0 0/100%;
    }
  }
  .zfb {
    .icon {
      background: url('../../assets/images/shopCar/zfb.svg') no-repeat 0 0/100%;
    }
  }
  .pay-item:last-child {
    margin: 0;
  }
}
.disabled {
  width: 21px;
  height: 21px;
  background: url(../../assets/images/shopCar/disabled.svg) no-repeat center;
}
</style>
