/*
 * @version: 1.0
 * @Author: 张勇康
 * @Date: 2022-08-04 16:48:00
 */
import axios from '@/utils/request'

const apis = {
  // register: '/drinks/app_no_login/v1/user/register', // 注册
  register: '/drinks/shop_no_login/v1/user/offline/register', // 注册
  distributor: '/drinks/shop/v1/order/offline/add/distributor', // 注册分销商
  encryption: '/uic/open/getEncryptedString', // 获取加密参数
  getCodes: `/drinks/direct/smsCode`, // 获取验证码
  verifyCode: '/drinks/smsCode/verify',
  login: 'drinks/login/v1/in', // 登录
  resetPsaaword: '/uic/web/user/reset/password/bycode', // 修改密码,
  getMineInfo: '/drinks/app/v1/user',
  getMyAchievement: '/drinks/shop/v1/order/offline/queryPerformanceStatistics', // 查询我的业绩统计
  getSubordinateOrderList: '/drinks/shop/v1/order/offline/querySubordinateOrderList', // 查询下级订单
  getOfflineOrderList: '/drinks/shop/v1/order/offline/queryOfflineOrderList', // 查询我的订单
  getDistributionPerformance: '/drinks/shop/v1/order/offline/queryDistributionPerformance', // 查询分销业绩
  getMyTeamAchievement: '/drinks/app/v1/agent/teamdetil', // 查看app我的业绩，
  getShareQrCode: '/drinks/shop/v1/user/offline/app/v1/user/qrcode',
  switchOver: '/drinks/app/v1/user/switchover', // 切换身份
  getUserTypeList: '/drinks/app/v1/getUserTypeList', // 获取身份列表
  getDirectPushList: '/drinks/app/v1/user/myLowerLevel', // 获取我的直推
  getCurrentData: '/drinks/app/v1/agata/getBabyBoxInfo', // 获取本期数据
  getFansList: '/drinks/app/v1/agent/teamorder/fans',
  getAgate: '/drinks/app/v1/myUserInfo', // 当前聚宝盆元宝金额
  getSummary: `/drinks/shop/v1/user/offline/summary/`, // 当前登陆用户信息
  divideStatistics: `/drinks/shop/v1/order/offline/divideStatistics`, // 分成_金豆
  putUser: `/drinks/shop/v1/user/offline/` // 更新当前用户信息
}

export function getDivideStatistics(params) {
  return axios({
    url: `${apis.divideStatistics}`,
    method: 'POST',
    data: params
  })
}

// 获取用户详情
export function putUserOffline(params) {
  return axios({
    url: `${apis.putUser}`,
    method: 'PUT',
    data: params
  })
}

// 获取用户详情
export function getSummary(id) {
  return axios({
    url: `${apis.getSummary}${id}`,
    method: 'GET'
  })
}

export function distributor(params) {
  return axios({
    url: apis.distributor,
    method: 'POST',
    data: params
  })
}

export function register(params) {
  return axios({
    url: apis.register,
    method: 'POST',
    data: params
  })
}

export function encryption(params) {
  return axios({
    url: apis.encryption,
    method: 'GET',
    data: params
  })
}

export function getCodes(params) {
  return axios({
    url: apis.getCodes,
    method: 'POST',
    data: params
  })
}
export function verifyCode(params) {
  return axios({
    url: apis.verifyCode,
    method: 'POST',
    data: params
  })
}
export function login(params) {
  return axios({
    url: apis.login,
    method: 'post',
    data: { ...params, json: 1, loginType: 110, style: 'drinks', encrypt: true, from: 4 },
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
export function resetPsaaword(params) {
  return axios({
    url: apis.resetPsaaword,
    method: 'PUT',
    data: params
  })
}
export function getMineInfo(params) {
  return axios({
    url: apis.getMineInfo,
    method: 'GET',
    params
  })
}
// 获取本期数据
export function getCurrentData(params) {
  return axios({
    url: apis.getCurrentData,
    method: 'post',
    data: params
  })
}

export function getDistributionPerformance(params) {
  return axios({
    url: apis.getDistributionPerformance,
    method: 'GET',
    params
  })
}

export function getOfflineOrderList(params) {
  return axios({
    url: apis.getOfflineOrderList,
    method: 'POST',
    data: params
  })
}
export function getSubordinateOrderList(params) {
  return axios({
    url: apis.getSubordinateOrderList,
    method: 'GET',
    params
  })
}

export function getMyAchievement(params) {
  return axios({
    url: apis.getMyAchievement,
    method: 'GET',
    params
  })
}
export function getMyTeamAchievement(params) {
  return axios({
    url: apis.getMyTeamAchievement,
    method: 'GET',
    params
  })
}
export function getShareQrCode(params) {
  return axios({
    url: apis.getShareQrCode,
    method: 'GET',
    params
  })
}

export const switchOver = (identifyId) => {
  return axios({
    url: `${apis.switchOver}/${identifyId}`,
    method: 'GET'
  })
}

export const getUserTypeList = () => {
  return axios({
    url: apis.getUserTypeList,
    method: 'GET'
  })
}

export const getDirectList = () => {
  return axios({
    url: apis.getDirectPushList,
    method: 'GET'
  })
}

export const getFansList = (params) => {
  return axios({
    url: apis.getFansList,
    method: 'GET',
    params
  })
}
export const getAgate = () => {
  return axios({
    url: apis.getAgate,
    method: 'GET'
  })
}

export default {
  putUserOffline,
  getSummary,
  login,
  getCodes,
  encryption,
  register,
  resetPsaaword,
  getMineInfo,
  verifyCode,
  getCurrentData,
  getUserTypeList,
  switchOver,
  getDirectList,
  getFansList
}
