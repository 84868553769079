/*
 * @version: 1.0
 * @Author: 杨慢慢
 * @Date: 2022-10-27 10:38:21
 */
import { createApp } from 'vue'
import * as echarts from 'echarts'
import App from './App.vue'
import router from './router/config-router' // 路由定义
import store from './store' // 状态管理
import com from './components'
import './global.scss' // 引入全局样式
import 'vue-cropper/dist/index.css'
import 'amfe-flexible/index'
import '../node_modules/swiper/swiper.scss'
import './permission'
import 'weixin-jsapi'
import 'vant/lib/index.css'
import visibilityChange from './utils/visibilityChange'
import VueLazyLoad from 'vue-lazyload'
// import Vconsole from 'vconsole'
// new Vconsole();

// import './utils/scoket'
import {
  Calendar,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  Field,
  Cell,
  CellGroup,
  Overlay,
  Loading,
  Form,
  Toast,
  Switch,
  Area,
  Popup,
  NavBar,
  Icon,
  Dialog,
  ActionSheet,
  List,
  DatetimePicker,
  Step,
  Steps,
  Lazyload,
  Picker,
  Tab, 
  Tabs
} from 'vant'
// Toast.setDefaultOptions({ duration: 900000 })
const app = createApp(App)
// app.config.devtools = true
app.config.globalProperties.ECharts = echarts
app.use(Button).use(Checkbox).use(Radio).use(RadioGroup)
app.use(Field)
app.use(Calendar)
app.use(Cell)
app.use(CellGroup)
app.use(Overlay)
app.use(Loading)
app.use(Form)
app.use(Toast)
app.use(Switch).use(Area).use(Popup).use(Dialog)
app.use(NavBar)
app.use(Icon)
app.use(ActionSheet)
app.use(List)
app.use(DatetimePicker)
app.use(Step)
app.use(Steps)
app.use(Lazyload)
app.use(Picker)
app.use(Tab)
app.use(Tabs)
app.use(store).use(router).use(com).mount('#app')
// app.use(VueLazyLoad, {
//   loading: require('@/assets/images/home/home-banner.png'),
//   error: require('@/assets/images/home/home-banner.png')
// })
visibilityChange()
