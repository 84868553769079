<!--
 * @Descripttion:
 * @version: 1.0
 * @Author: 周达
 * @Date: 2022-09-13 09:11:55
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-01-16 17:24:30
-->
<template>
  <van-list
    class="list"
    v-model:loading="listLoading"
    finished-text="没有更多了"
    :finished="finished"
    v-if="listData.length && loading"
  >
    <div class="card-list">
      <div class="card-item" v-for="item in listData" :key="item.id">
        <div class="item-top">
          <span class="title">
            <slot :name="listOptions.title" :record="item">{{ item[listOptions.title] }}</slot>
          </span>
          <span class="pay">
            <slot :name="listOptions.pay" :record="item">{{ item[listOptions.pay] }}</slot>
          </span>
        </div>
        <div class="item-bottom">
          <span class="balance">
            <slot :name="listOptions.balance" :record="item"> {{ item[listOptions.balance] }}</slot>
          </span>
          <span class="time">
            <slot :name="listOptions.time" :record="item">{{ item[listOptions.time] }}</slot>
          </span>
        </div>
      </div>
    </div>
  </van-list>
  <div v-else-if="loading" class="default">
    <div class="img"></div>
    <p>暂无明细数据</p>
  </div>
</template>

<script>
import { ref } from 'vue'
import { Toast } from 'vant'
export default {
  name: 'cardList',
  props: {
    listOptions: {
      type: Object,
      default: () => {}
    },
    listData: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const listLoading = ref(false)
    const toast = ref(null)
    const finished = ref(false)
    const loading = ref(false)

    const startLoading = () => {
      toast.value = Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      })
    }

    const clearLoading = () => {
      toast.value.clear()
      loading.value = true
    }

    const showFinished = () => {
      finished.value = true
    }

    return {
      loading,
      listLoading,
      toast,
      finished,
      startLoading,
      clearLoading,
      showFinished
    }
  }
}
</script>

<style scoped lang="scss">
@function rem($px) {
   @return calc(#{$px} / 35.7 * 1rem);
}

.card-list {
  width: 100%;
  border-top: 0.5px solid #eee;
  .card-item {
    padding: rem(15);
    border-bottom: rem(0.5) solid #eee;
    padding: rem(16) 0;
    margin: 0 rem(12);
    .item-top,
    .item-bottom {
      display: flex;
      justify-content: space-between;
      //   align-items:
    }
    .item-top {
      margin-bottom: rem(4);
      .pay {
        font-size: rem(20);
        font-weight: bold;
        color: #343434;
        line-height: rem(26);
      }
      .title {
        font-size: rem(15);
        font-weight: 400;
        color: #343434;
        line-height: rem(22);
      }
    }

    .item-bottom {
      span {
        font-size: rem(13);
        font-weight: 400;
        color: #9d9d9d;
        line-height: rem(18);
      }
    }
  }
}
.default {
  width: 375px;
  border-top: 0.5px solid #eee;
  display: flex;
  flex-direction: column;
  align-items: center;

  .img {
    width: 108px;
    margin-top: 48px;
    height: 108px;
    background: url('../../assets/images/yb/default.png') no-repeat 0 0/100%;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    color: #bfbfbf;
    line-height: 22px;
  }
}
</style>
