<!--
 * @version: 1.0
 * @Author: 张勇康
 * @Date: 2022-08-04 16:11:22
-->
<template>
  <div class="search-box">
    <div class="back"><img src="@/assets/images/tabItem/arrow-left.png" /></div>
    <div class="search">
      <input type="text" v-model="val" @input="searchInput" placeholder="请输入关键词搜索" />
      <img src="@/assets/images/tabItem/search.png" />
    </div>
    <span>搜索</span>
  </div>
</template>
<script>
import { ref } from 'vue'
export default {
  name: 'Search',
  setup() {
    let val = ref('')

    function searchInput() {
      console.log(val.value)
    }
    return {
      val,
      searchInput
    }
  }
}
</script>
<style scoped lang="scss">
@function rem($px) {
  @return calc(#{$px} / 35.7 * 1rem);
}
.search-box {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  height: rem(44);
  z-index: 99;

  .back {
    width: rem(12);
    height: rem(20);
    margin-left: rem(12);
    margin-right: rem(16);
    img {
      width: 100%;
      height: 100%;
    }
  }

  .search {
    position: relative;
    input {
      width: rem(275);
      height: rem(32);
      background: #efefef;
      padding-left: rem(37);
      box-sizing: border-box;
      border: none;
      font-size: rem(13);
      border-radius: rem(4);
    }

    input::placeholder {
      color: #bfbfbf;
    }

    img {
      width: rem(20);
      height: rem(20);
      position: absolute;
      left: rem(12);
      top: 50%;
      transform: translateY(-50%);
    }
  }

  span {
    font-size: rem(14);
    color: #343434;
    margin-left: rem(16);
  }
}
</style>
