import request from '@/utils/request'

const apis = {
  getData: '/drinks/offline/v1/coin/detail',
  getInfo: '/drinks/offline/v1/coin/info',
  exchange: '/drinks/offline/v1/coin/convert'
}

export const getData = (data) => {
  return request({
    method: 'POST',
    url: apis.getData,
    data
  })
}

export const getInfo = () => {
  return request({
    method: 'GET',
    url: apis.getInfo
  })
}
export const exchange = (data) => {
  return request({
    method: 'post',
    url: apis.exchange,
    data
  })
}
