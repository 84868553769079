<!--
 * @Description:
 * @Date: 2024-01-12 10:21:11
 * @LastEditTime: 2024-01-16 17:24:58
-->
<template>
  <div>
    <div class="tabsContent">
      <div class="tabs_title">
        <div
          v-for="(item, index) in tabs"
          :key="item.id"
          :class="['title_item', item.isActive ? 'active' : '']"
          @click="handleItemTap(index, item)"
        >
          {{ item.value }}
        </div>
      </div>
      <div class="tabs_content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Tabs',
  props: {
    tabs: {
      type: Array,
      required: true
    }
  },

  data() {
    return {}
  },
  methods: {
    // 发生点击事件时触发
    handleItemTap(index, item) {
      this.$emit('tabClick', item)
    }
  }
}
</script>

<style lang="scss" scoped>
@function rem($px) {
  @return calc(#{$px} / 35.7 * 1rem);
}
.tabs_title {
  display: flex;
  background-color: #fff;
  font-size: rem(28);
}
/* 每个文字居中，且占1份 */
.title_item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: rem(20) 0;
}
/* 选中样式 */
.active {
  color: #333;
  font-size: rem(32);
  font-weight: 600;
  border-bottom: rem(1) solid blue;
}
</style>
