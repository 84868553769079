import { getShareParams } from '@/api/wx'
// import { useStore } from 'vuex'
// const store = useStore()
import store from '@/store'
import wx from 'weixin-js-sdk'
console.log(wx, 'wx')
export const wechatAuth = async (device, authurl) => {
  // if (!localStorage.getItem('MINE_INFO')) {
  //   await store.dispatch('user/getMineInfo')
  // }
  const mineInfo = JSON.parse(localStorage.getItem('MINE_INFO'))
  console.log(mineInfo.nickname, 'll')
  const code = mineInfo.inviteCode || ''
  let shareConfig = {
    title: `【${mineInfo.nickname}】邀请您 加入财神酒售卖系统！`,
    desc: '',
    link: `${window.location.origin}/register/${code}`,
    imgUrl: 'https://testticketsapi.shangjingu.cn/image/2022/0909/1046/fAe0WY.png'
  }
  console.log('device', decodeURIComponent(device == 'ios' ? window.entryUrl : authurl))
  const { success, data } = await getShareParams({
    url: decodeURIComponent(device == 'ios' ? window.entryUrl : authurl)
  })

  if (success) {
    console.log(data, '签名')
    wx.config({
      debug: false,
      appId: data.appId,
      timestamp: data.timestamp,
      nonceStr: data.nonceStr,
      signature: data.signature,
      jsApiList: [
        'updateAppMessageShareData',
        'updateTimelineShareData',
        'onMenuShareAppMessage',
        'onMenuShareTimeline'
      ]
    })

    wx.ready(() => {
      console.log('准备完成', wx)
      wx.updateAppMessageShareData({
        title: shareConfig.title,
        desc: shareConfig.desc,
        link: shareConfig.link,
        imgUrl: shareConfig.imgUrl,
        success: function (res) {
          console.log('分享完成', res)
          // 设置成功
          // shareSuccessCallback();
        }
      })
      // wx.updateTimelineShareData({
      //   title: shareConfig.title,
      //   link: shareConfig.link,
      //   imgUrl: shareConfig.imgUrl,
      //   success: function () {
      //     // 设置成功
      //     // shareSuccessCallback();
      //   }
      // })
      // wx.onMenuShareTimeline({
      //   title: shareConfig.title,
      //   link: shareConfig.link,
      //   imgUrl: shareConfig.imgUrl,
      //   success: function () {
      //     shareSuccessCallback()
      //   }
      // })
      // wx.onMenuShareAppMessage({
      //   title: shareConfig.title,
      //   desc: shareConfig.desc,
      //   link: shareConfig.link,
      //   imgUrl: shareConfig.imgUrl,
      //   success: function () {
      //     shareSuccessCallback()
      //   }
      // })
    })
    wx.error((res) => {
      console.log(res)
    })
  }
}

// function shareSuccessCallback() {
// if (!store.state.user.uid) {
//   return false
// }
// store.state.cs.stream({
//   eid: 'share',
//   tpc: 'all',
//   data: {
//     uid: store.state.user.uid,
//     truename: store.state.user.truename || ''
//   }
// })
// http.get('/pfront/member/share_score', {
//   params: {
//     uid: store.state.user.uid
//   }
// })
// }
