import request from '@/utils/request'

const apis = {
  getData: '/drinks/offline/v1/balance/detail',
  transfer: '/drinks/offline/v1/balance/balanceOrGoldTransfer',
  getBalance: '/drinks/offline/v1/balance/detail',
}

// 储值卡明细
export function getBalancePage(data) {
  return request({
    url: apis.getBalance,
    method: 'POST',
    data
  })
}

// 获取余额明细数据
export const getData = (data) => {
  return request({
    method: 'POST',
    url: apis.getData,
    data
  })
}

// 余额转账
export const transfer = (data) => {
  return request({
    method: 'POST',
    url: apis.transfer,
    data
  })
}
